import React from "react";
import Header from "./components/Header/Header.jsx";
import Doitnow from "./components/Header/Doitnow.jsx";
import Aboutus from "./components/middlepart/Aboutus.jsx";
import OurServices from "./components/middlepart/OurServices.jsx";
import DisplayButton from "./components/middlepart/Displaybutton.jsx";
import ContactUs from "./components/footer/Contactus.jsx";
import { useState } from "react";
function App() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="App">
      <Header />
      <Doitnow />
      <Aboutus />
      <DisplayButton isVisible={isVisible} setIsVisible={setIsVisible} />
      <OurServices isVisible={isVisible} setIsVisible={setIsVisible} />
      <ContactUs />
    </div>
  );
}

export default App;
