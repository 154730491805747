import React from "react";
import styles from "./header.module.css";
import logo from "../../assets/logo.jpg";

const Header = () => {
  const handleclick = (child) => (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    if (child === "home") {
    } else if (child === "Services") {
      window.scrollBy({
        top: 1173,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollBy({
        top: 1920,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className={styles.headercontainer}>
      <img src={logo} alt="" className={styles.logo}></img>
      <ul className={styles.navlinkul}>
        <li className={styles.navlinkli}>
          <a className={styles.navlinkl} href="" onClick={handleclick("home")}>
            Home
          </a>
        </li>
        <li>
          <a
            className={styles.navlinka}
            href=""
            onClick={handleclick("Services")}
          >
            Services
          </a>
        </li>
        <li>
          <a
            className={styles.navlinka}
            href=""
            onClick={handleclick("Contact us")}
          >
            Contact Us
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
