import React from "react";
import styled from "styled-components";
import corporate from "../../assets/corporate.jpg";
import privateparties from "../../assets/privateparties.jpg";
import weddings from "../../assets/weddings.jpg";

import sampleimg from "../../assets/sample1.jpg";

const ServicesContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

const CardWrapper = styled.div`
  perspective: 1000px;
  width: calc(33.33% - 20px);
  max-width: 350px;
`;

const Card = styled.div`
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  width: 100%;
  height: 450px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  position: relative;
  &:hover {
    transform: rotateY(180deg);
  }
`;

const CardFront = styled.div`
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardBack = styled.div`
  backface-visibility: hidden;
  transform: rotateY(180deg);
  padding: 20px;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const CardContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  text-align: justify;
  flex-grow: 1;
  overflow-y: auto;
`;

const services = [
  {
    title: "Weddings",
    description: "Create your dream wedding with our comprehensive wedding planning services, including venue selection, decor design, catering, and more.",
    imageUrl: weddings,
    backContent: "• Full-Service Wedding Planning: Transform your dream into reality. We handle everything from venue to vendors. \n\n" +
      "• Destination Weddings: Say 'I do' anywhere in the world. Let us craft your perfect wedding, whether on a tropical beach or in a historic castle. \n\n" +
      "• Elopements & Intimate Ceremonies: Small affair, big impact. We will plan every detail of your intimate celebration, making it as unique as your love story.",
  },
  {
    title: "Corporate",
    description: "Elevate your company's image with meticulously planned corporate events, from conferences and product launches to gala dinners and team-building retreats.",
    imageUrl: corporate,
    backContent: "• Conferences & Seminars: Seamless corporate events from start to finish. We handle logistics, you focus on content.\n\n" +
      "• Product Launches: Unveil with impact. Strategic planning for unforgettable product debuts that captivate your audience.\n\n" +
      "• Galas & Award Ceremonies: Celebrate achievements in style. Elegant events that inspire and leave a lasting impression.\n\n" +
      "• Team-Building Events: Boost collaboration and morale. Tailored activities that align with your company's culture and goals.",
  },
  {
    title: "Private Parties",
    description: "Celebrate life's milestones in style with our bespoke private party planning, whether it's a birthday celebration, anniversary party, or intimate gatherings.",
    imageUrl: privateparties,
    backContent: "• Birthday Celebrations: Create milestone memories. We design parties that reflect your personality, delighting you and your guests.\n\n" +
      "• Anniversary Parties: Honor your journey together. Celebrate your love with elegant decor, gourmet cuisine, and heartfelt touches.\n\n" +
      "• Holiday Gatherings: Spread joy and warmth. From Christmas festivities to New Year's Eve bashes, we'll make your holiday events truly special.",
  },
  {
    title: "Additional Services",
    description: "Elevate your event with our expert services in venue selection, design, and catering coordination. We transform your vision into an unforgettable experience.",
    imageUrl: sampleimg,
    backContent: "• Venue Selection: Access our network of perfect settings, from grand ballrooms to unique outdoor locations.\n\n" +
      "• Event Design and Decor: Create stunning ambiance with expert design, including florals, lighting, and custom tablescapes.\n\n" +
      "• Catering Coordination: Partner with top caterers to craft a delightful culinary experience for your guests."
  },
];

const OurServices = ({ isVisible }) => {
  return (
    <ServicesContainer isVisible={isVisible}>
      <Title>Our Services</Title>
      <CardsContainer>
        {services.map((service, index) => (
          <CardWrapper key={index}>
            <Card>
              <CardFront>
                <CardImage src={service.imageUrl} alt={service.title} />
                <CardContent>
                  <CardTitle>{service.title}</CardTitle>
                  <CardDescription>{service.description}</CardDescription>
                </CardContent>
              </CardFront>
              
              <CardBack>
                <CardContent>
                  <CardTitle>{service.title}</CardTitle>
                  <CardDescription>{service.backContent}</CardDescription>
                </CardContent>
              </CardBack>
            </Card>
          </CardWrapper>
        ))}
      </CardsContainer>
    </ServicesContainer>
  );
};

export default OurServices;

