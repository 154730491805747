import React from "react";
import styles from "./displaybutton.module.css";

const DisplayButton = ({ isVisible, setIsVisible }) => {
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className={styles.container}>
      <button
        className={styles.additionalservicesbutton}
        onClick={toggleVisibility}
      >
        {isVisible ? "Hide Services" : "View Services"}
      </button>
    </div>
  );
};

export default DisplayButton;
