import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contactus.css";
import call from "../../assets/call.png";
import linkedin from "../../assets/linkedin.png";
import mail from "../../assets/mail.png";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    console.log('Form Data:', formData);

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    emailjs
      .send(
        "service_m9ezmi6", // EmailJS service ID
        "template_aprsypf", // EmailJS template ID
        templateParams, // Template parameters
        "BSozMi3h8Y7pw3F3t" // User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message, please try again later.");
        }
      );

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="contact-container">
      <h2 className="contact-title">Contact Us</h2>
      <p className="description">
        Ready to discuss your upcoming event? Contact us today to schedule a
        consultation and let us bring your vision to life with creativity,
        passion, and precision.
      </p>
      <div className="contact-content">
        <div className="contact-info">
          <p>
            <a href="tel:+447404971067">
              <img className="icon" src={call} alt="Phone" />
            </a>
            <span>+44 7404971067</span>
          </p>
          <p>
            <a href="https://www.linkedin.com/company/do-it-now-events/">
              <img className="icon" src={linkedin} alt="LinkedIn" />
            </a>
            <span className=""> Connect </span>
          </p>
          <p>
            <a href="mailto:doitnoweventsltd@gmail.com">
              <img className="icon" src={mail} alt="Email" />
            </a>
            <span>Doitnoweventsltd@gmail.com</span>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <div className="form-gp">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-fa"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className="form-la"
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="form-input"
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-input"
            />
            <textarea
              name="message"
              placeholder="Enter your entire details with name and contact."
              value={formData.message}
              onChange={handleChange}
              className="form-input"
            />
          </div>
          <button type="submit" className="form-button">
            Send ➔
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;

